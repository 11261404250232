
<template>
    <div class="vertical-wrapper" style="">
       
        <md-progress-bar class="md-accent" md-mode="determinate" :md-value="nextRequest" style="flex: 0 0 3pt"></md-progress-bar>

        <div v-if="deviceNotConnected" style="overflow-y: scroll">
            <h3>Mit Flow Control verbinden</h3>

            <div class="vertical-wrapper wifi-info color" >
                <span style="text-align: left; font-weight: bold;">Schritt 1:</span>
                <div class="horizontal-wrapper">
                    <div style="text-align: left">
                        Öffne die Wlan Einstellungen von deinem Smartphone
                        <br>
                        <br>    
                        Suche hier nach dem Netzwerk mit dem Name <span style="font-weight: bold">flow control</span>
                    </div>
                    <div :style="`flex: 0 0 100pt; background-image: url(${require('../../assets/tutorial/ios-16-iphone-13-pro-settings-wifi-secure-network.png')})`" class="icon" ></div>
                </div>
            </div>
            <div class="vertical-wrapper wifi-info" >
                <span style="text-align: left; font-weight: bold;">Schritt 2:</span>
                <div class="horizontal-wrapper">
                    <div :style="`flex: 0 0 100pt; background-image: url(${require('../../assets/tutorial/ios-16-iphone-14-pro-wifi-name-more-info-password-on-tap.png')})`" class="icon" ></div>

                    <div style="text-align: left">
                        Verbinde Sie sich mit dem Netzwerk <span style="font-weight: bold">flow control</span>
                        <br>
                        <br>    
                        Das Wlan Passwort ist <span style="font-weight: bold">12345678</span>
                        <br>    

                    </div>
                </div>
            </div>

            <div class="vertical-wrapper wifi-info color">
                <span style="text-align: left; font-weight: bold; margin: 5pt 0">Schritt 3:</span>
                <div class="horizontal-wrapper">

                    <div style="text-align: left">
                        Bestätige die Verbindung mit <span style="font-weight: bold">flow control</span>
                        <br>
                        <br>
                        Bei manchen Geräten kommt eine Meldung das dieses Netzwerk keinen Internetzugriff hat, <span style="font-weight: bold">bestätigen</span> sie diese Benachrichtigung    
                    </div>
                    <div :style="`flex: 0 0 100pt; background-image: url(${require('../../assets/tutorial/ios-16-iphone-14-pro-wifi-name-more-info-password-on-tap.png')})`" class="icon" ></div>

                </div>
            </div>
           

        </div>
        <div v-else>
            <p>Verfügbare Wlan Netzwerke</p>
            <div v-for="w in wifis" :key="w.key" class="horizontal-wrapper" style="margin: 10pt" v-ripple v-on:click="selectWifi(w)">
                <div  class="wifi-icon icon" style="flex: 0 0 auto; margin: 0 20pt; width: 20pt;margin: 10pt;" :style="``">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style="margin: auto">
                        <path d="M 54.2 202.9 C 123.2 136.7 216.8 96 320 96 S 516.8 136.7 585.8 202.9 C 598.6 215.1 618.8 214.7 631 202 S 642.8 169 630.1 156.8 C 549.7 79.5 440.4 32 320 32 S 90.3 79.5 9.8 156.7 C -2.9 169 -3.3 189.2 8.9 202 S 41.4 215.2 54.1 202.9 Z " :fill="w.strength > 1 ? colors[w.strength] : 'grey'"/>
                        <path d="M 320 256 C 376.8 256 428.6 277.1 468.2 312 C 481.5 323.7 501.7 322.4 513.4 309.2 S 523.8 275.7 510.6 264 C 459.8 219.2 393 192 320 192 S 180.2 219.2 129.5 264 C 116.2 275.7 115 295.9 126.7 309.2 S 158.6 323.7 171.9 312 C 211.4 277.1 263.2 256 320.1 256 Z" :fill="w.strength > 0 ? colors[w.strength] : 'grey'"/>
                        <path d="M 384 416 A 64 64 0 1 0 256 415 A 64 64 0 1 0 384 415 Z" :fill="colors[w.strength]"/>
            
                    </svg>
                </div>
                <div class="horizontal-wrapper">
                    <p style="text-align: left;margin-bottom: 0; font-weight:bold">{{w.name}}</p>
                    <div style="flex: 0 0 5pt"></div>
                    <p style="text-align: left;margin-bottom: 0;"> {{w.strengthValue}}dBm</p>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>
<script>
import swivel from 'swivel'

export default {
    name: 'device-connector-wifi',
    methods:{
        requestWifis(){
            if(!this.requestingLoop)
                return

            this.nextRequest = 0;

            swivel.emit('get-wifis', '')

            console.log('Request Wifis');
            // repeat request after last request wifis all 20 seconds
            setTimeout(() => this.requestWifis(), 20 * 1000)

            
        },
        selectWifi(wifi){
            this.requestingLoop = false

            // move on to the password input
            this.$router.push({name: 'device-add-view-wifi-password', params:{ssid: wifi.name}})
        }
    },  
    data(){
        return{
            requestingLoop: true,
            wifis: [],
            colors: ['#fc0303', '#448aff', '#16c742'],
            deviceNotConnected: true,
            nextRequest: 0
        }
    },
    beforeRouteEnter(to, from, next){
        next((vm) => {
            vm.requestingLoop = true

            vm.requestWifis()
            setInterval(() => vm.nextRequest += 1 , 200)
        })
    },
    beforeRouteLeave(to, from, next){
        this.requestingLoop = false
        next()
    },
    mounted(){
                  const vm = this;

        navigator.serviceWorker
            .register('/service-worker.js')
            .then(navigator.serviceWorker.ready)
            .then(function () {
                swivel.on('set-wifis', function handler(context, data) {
                    // do something with ...data
                    const text = data;
                    vm.deviceNotConnected = false
                    vm.wifis = []
                    const wifistext = text.split('<body>')[1].split('</body>')[0]
                    wifistext.split('\n').forEach(e => {
                        if (e.trim() == '')
                            return

                        const wifiChunks = e.split(',')
                        const strengthValue = parseInt(wifiChunks[3])

                        let strength = 0
                        if (strengthValue > -67) {
                            strength = 2
                        } else if (strengthValue > -90) {
                            strength = 1
                        }

                        const w = { name: wifiChunks[0].trim(), strengthValue: wifiChunks[3], strength: strength, key: vm.$uuid.v4() }
                        vm.wifis.push(w)

                    })

                })
            })
            .catch(e => {
                console.log(e);
                setTimeout(() => vm.requestWifis(), 20 * 1000)

            });
    }
}
</script>

<style>
div.wifi-info{
    padding: 20pt;
}
div.wifi-info.color{
    background-color: rgba(249, 156, 27, 0.3);
   /* background-color: rgb(from #F99C1B r g b / 50%);*/

}
</style>