
<template>
    <div class="vertical-wrapper">
        <div class="vertical-wrapper" style="overflow: scroll; ">
            <appointment-calendar></appointment-calendar>
        </div>

        <md-button class="md-fab md-primary md-invert" style="position: absolute; right: 5px; bottom: 65px; 
        height: 35pt; width: 35pt; margin-bottom: env(safe-area-inset-bottom)" v-on:click="addEvent">
            <md-icon :md-src="require('../../../assets/plus-solid.svg')" style="fill: white;" ></md-icon>
        </md-button>
    </div>
</template>
><script>
import appointmentCalendarVue from '@/components/appointment-calendar.vue'
export default {
    name: 'tank-view-repeat',
    components: {
        'appointment-calendar':appointmentCalendarVue
    },
    methods:{
        addEvent() {
            this.$router.push({ name: 'popup-task-view', params:{ taskId : this.$uuid.v4()} })
        }
    }
}
</script>