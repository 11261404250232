import deviceConnectorVue from './device-connector.vue'
import deviceConnectorScanVue from './device-connector-scan.vue'
import deviceConnectorWifiVue from './device-connector-wifi.vue'

import deviceConnectorWifiPasswordVue from './device-connector-wifi-password.vue'

function plugin(Vue, router = 0) {
    Vue.component('device-connector', deviceConnectorVue)


    const routerAddition = {
        path: '/device-add', name: 'device-add-view', redirect: { name: 'device-add-view-scan'},component: deviceConnectorVue, children: 
            [
                { path: '/device-add/scan', name: 'device-add-view-scan', component: deviceConnectorScanVue },
                { path: '/device-add/wifi', name: 'device-add-view-wifi', component: deviceConnectorWifiVue, children: [
                    { path: '/device-add/wifi/password/:ssid', name: 'device-add-view-wifi-password', props:true, component: deviceConnectorWifiPasswordVue },

                ] },

            ]
        }
    // if router is added modify routes
    if(router)
        router.addRoute(routerAddition)
    
    Vue.$deviceConnector = {}
    Vue.$deviceConnector.open = function(){
        router.push({ name: 'device-add-view'})
    }
}
// Install by default if using the script tag
if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(plugin)
}

export default plugin
const version = '__VERSION__'

// Export all components too
export {
    deviceConnectorVue,
    version
}
