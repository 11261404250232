<template>
    <div class="vertical-wrapper">
        <div style="flex: 1 0 0" class="horizontal-wrapper">
          
        </div>
     
        <div class="icon" :style="`
            margin: 5pt; 
            margin-bottom: 10pt; 
            flex: 0 0 55pt; 
            background-image: url(${require('../assets/Craft-Ing_Craft-FlowControl-Schriftzug--melogo.svg')})`" >

        </div>
            
        <div class="horizontal-wrapper md-navbar" style="flex: 0 0 auto; margin: 0 5pt; margin-top: 10px;">
            <router-link :to="{ name: 'login-view' }" v-ripple class="item">Login</router-link>
            <router-link :to="{ name: 'register-view' }" v-ripple class="item">Register</router-link>

        </div>
        <router-view></router-view>
        <div style="flex: 1 0 0">

        </div>
    </div>
</template>
<script>
export default {
    name: 'start-view'
}
</script>
<style></style>