<template>
    <header class="horizontal-wrapper settings-header" style="flex: 0 0 auto">
        <md-button style="margin: auto 0; flex: 0 0 15pt" v-on:click="$router.push(route)">
            <md-icon :md-src="require('../assets/arrow-left-solid.svg')"></md-icon>
        </md-button>
        <h2 style="flex: 1 0 0; text-align: left; margin-left: 40px">{{title}}</h2>
    </header>
</template>
<script>
export default {
    name: 'setting-header',
    props: {
        title:String,
        route: [String, Object]
    }
}
</script>
<style>
header.settings-header {
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
    background-color: #fff;
}
header.settings-header .md-button {
    min-width: 30px;
}
</style>