<template>
    <div class="vertical-wrapper">
        <settings-header title="Informationen" :route="{name:'menu-menu-view'}"/>
        <div class="vertical-wrapper" style="padding: 0 10pt; overflow-y: scroll">
            <div class="icon" :style="`flex: 0 0 55pt; background-image: url(${require('../../assets/Craft-Ing_Craft-FlowControl-Schriftzug--melogo.svg')})`" >

            </div>
            
            <p style="margin-top: 2pt">ist ein Produkt von</p>
            <div  class="icon"  :style="`flex: 0 0 77pt; background-image: url(${require('../../assets/craft-ing.jpg')})`">

            </div>
            <div>
                Verantwortlich für den Internetauftritt (Diensteanbieter i.S.d. TMG):<br>
                <br>
                Craft-Ing GmbH<br>
                Daniel Bassing<br>
                Irrhainstraße 6<br>
                90427 Nürnberg<br>
                E-Mail: info@craft-ing.de<br>
                <br>
                Registergericht Nürnberg: HRB 39668<br>
                <br>
                USt-ID: DE315240540<br>
                <br>
                EORI: DE758036151574067<br>
            </div>
            <h4 style="margin-bottom: 5pt">Made by</h4>
            <div class="icon"  :style="`flex: 0 0 77pt; background-image: url(${require('../../assets/frapp-logo-mit-schriftzug.svg')})`"></div>
            <h4>Ihre Anlagen voll vernetzt</h4> 
            
        </div>
    </div>
</template>
<script>
import settingHeaderVue from '../../components/setting-header.vue';

export default {
    name: 'menu-devices-view',
    components:{
        'settings-header':settingHeaderVue
    }
}
</script>