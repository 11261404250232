<template>
    <div class="vertical-wrapper">
        <div style="flex: 0 0 auto">
            <p>Scan Device</p>
        </div>
        <div></div>

        <div class="vertical-wrapper"  style="flex: 0 0 100pt; margin: 20pt 20%; ">
            <qrcode-stream @detect="onDetect"></qrcode-stream>
            <p>{{deviceId}}</p>
        </div>
        <div style="flex: 0.5 0 0"></div>
        <div style="flex: 0 0 auto;  margin: 0 20pt">
            <md-field style="flex: 0 0 auto;">
                <label for="">Device Code</label>
                <md-input v-model="deviceId"></md-input>
            </md-field>
        </div>

        <div style="flex: 0 0 auto; margin: 0 20pt; margin-bottom: 30pt" class="horizontal-wrapper">
            <md-button class="md-raised" style="margin: 0;" v-on:click="removeInProduction">Add</md-button>
            <div></div>
            <md-button class="md-raised md-primary" style="margin: 0;" v-on:click="addDevice">Next</md-button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'device-connector-scan',
    computed:{
        ...mapGetters(['token'])
    },
    methods:{
        async onDetect(qrcode){
            const { content } = await qrcode
            this.deviceId = content

            this.addDevice()


            console.log(this.deviceId);
            //this.next()
        },
        addDevice(){
           
            fetch(`${this.$hostname}/devices/add`, {
                method: 'POST',
                headers: { 
                    "Authorization": this.token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    deviceId: this.deviceId,
                    name: 'New Device'
                })
            }).then(async e => {
                if (e.status == 200) {
                    this.$router.push({ name: 'menu-devices-view' })
                } else if (e.status == 403) {
                    this.$router.push({ name: 'start-view ' })
                }
            })

        },
        removeInProduction(){
            /*
           
            */
        }
    },
    data(){
        return{
            deviceId: ''
        }
    }
}
</script>