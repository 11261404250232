import Vue from 'vue'
import App from './App.vue'


import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial);

//Cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
Vue.$cookies.config('30d');

//Moment
const moment = require('moment')
require('moment/locale/de')
Vue.use(require('vue-moment'), {
  moment
})
import VueUUID from 'vue-uuid'
Vue.use(VueUUID);

import Ripple from 'vue-ripple-directive'
Vue.directive('ripple', Ripple);


import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
Vue.component('vue-datetime', Datetime);
Vue.use(Datetime)

import createStore from '@/store/index.js'
const options = {
  confirmButtonColor: '#03ac13',
  cancelButtonColor: '#ff7674',
};

import VueRouter from 'vue-router'
import myrouter from '@/router/index.js'
Vue.use(VueRouter)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2, options);

import deviceConnector from './components/device-connector'
Vue.use(deviceConnector, myrouter);
 
import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

import './assets/layout.css'
import './assets/theme.css'
import './registerServiceWorker'

//import './registerServiceWorker.js';

Vue.config.productionTip = false

// setup http routes
const inProduction = !(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && typeof console !== 'undefined')

Vue.prototype.$hostname = inProduction? '': 'https://craft-ing.frappgmbh.de'
//Vue.prototype.$hostname = inProduction ? '' : 'http://localhost:6501'

new Vue({
  render: h => h(App),
  store: createStore(),
  router: myrouter,
}).$mount('#app')
