<template>
    <div class="control-element horizontal-wrapper" style="flex: 0 0 auto;">
        <div class="icon" style="flex: 0 0 60pt">
            <slot :stroke-color="strokeColor" :fill-color="fillColor" ></slot>
        </div>

        <div class="vertical-wrapper" style="margin-right: 5pt">
            <h4 style="text-align: left; margin-top:6pt; margin-bottom: 2pt">{{name}}</h4>
           <md-button-selector class="md-accent"
            style="margin: 5pt 0" 
            :options="['Auto', 'An', 'Aus']" 
            v-model="controlValue"

            ></md-button-selector>
            <!--
                colors in 
                :colors="['rgb(30, 144, 255)', 'rgb(22, 216, 87)', 'rgb(255, 82, 82)']"
            -->
        </div>
    </div>
</template>
<script>
import mdButtonSelectorVue from './md-button-selector.vue'
export default {
    name: 'control-element',
    props: { name : String, value: String , state: [Number, Boolean, String], hand: [Number, Boolean, String]},
    components: {
        'md-button-selector':mdButtonSelectorVue
    },
    watch: {
        controlValue(){
            this.$emit('input', this.controlValue)
            this.$emit('state-changed')
        }
    },
    computed:{
        strokeColor() {
            return (this.controlValue == 'An' || this.controlValue == 'Aus' ) ? 'orange' : 'black'

        },
        fillColor() {
            return (parseInt(this.state) % 2) ? '#00FF00' : 'lightgrey'
        },
    },
    data(){
        let value = this.$props.value
        if(this.$props.hand)
            value = value? 'An': 'Aus'

        //console.log(this.$props);
        return{
            controlValue: value,
            running: false,
        }
    },
    mounted(){
       // this.$emit('state-changed')


    }
}
</script>
<style>
.control-element{
    margin: 10pt 5pt; 
    box-shadow: #ccc 0pt 0pt 2pt 1pt; 
    padding: 2pt; 
    background-color: white;
}
</style>