
<template>
    <div class="vertical-wrapper" >
        <control-element name="Tank Befüllung" v-slot="{ fillColor, strokeColor }" v-model="valve" v-on:state-changed="stateChange" :state="this.systemState.filling" :hand="this.activeDevice.valveHand">

               <svg viewBox="0 0 100 100" style="margin: 20px">
                <path d="M78 35, a1,1 0 0,0 -56,0" style="stroke-width:6" :fill="fillColor" :stroke="strokeColor"/>
                <line  x1="20" y1="36" x2="80" y2="36" style="stroke-width:6" :stroke="strokeColor"/>  

                <line  x1="50" y1="35" x2="50" y2="70" style="stroke-width:6" :stroke="strokeColor"/>  
                <polygon points="2 40,50 70, 2 98" style="stroke-width:6;" :fill="fillColor" :stroke="strokeColor"/> 
                <polygon points="98 40,50 70, 98 98" style="stroke-width:6;" :fill="fillColor" :stroke="strokeColor"/> 
            </svg> 
        </control-element>

        <control-element v-if="displayHeating" name="Tank Beheizung" v-slot="{ fillColor, strokeColor }" v-model="heating" v-on:state-changed="stateChange" :state="this.systemState.heating" :hand="this.activeDevice.heatingHand">
            <svg viewBox="0 0 120 120" >
                <circle cx="60" cy="60" r="30" stroke-width="3" v-bind:fill="fillColor" v-bind:stroke="strokeColor" />
                <path d="m 47.5 65c 0 17 27 17 27 0 c 0 -8 -3 -17 -14 -24 c -3 -2 -1 1 0 3 c 2 5 1 10 -1 13 c -3 4 -7 3 -6 -3 c 1 -4 -6 3 -6 11" :fill="strokeColor"></path>
            </svg>
        </control-element>


        <div v-if="loaderActive" style="position: fixed; z-index: 100; top:0; left:0; height: 100vh; width: 100vw; background-color: rgba(0, 0, 0, 0.5);" class="vertical-wrapper" v-on:click.self="forceCloseLoader">
            <div class="vertical-wrapper" style="margin: 40% 20%; background-color: white; border-radius: 10pt">
                <div></div>
                <div style="flex: 0 0 auto">

                    <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
                </div>
                <div></div>

            </div>

        </div>

    </div>
</template>
<script>
import ControlElement from '@/components/control-element.vue'
export default {
    name: 'tank-view-control',
    components: {
        ControlElement
    },
    computed:{
        token() {
            return this.$store.getters.token
        },
        systemState() {
            return this.$store.getters.getSystemStatus
        },
        displayHeating(){
            return this.$store.getters.getDisplayHeating
        },
        loaderActive(){
            return this.$store.getters.getLoaderActive
        },
        activeDevice(){
            return this.$store.getters.getActiveDevice
        }
    },
    methods:{
        stateChange(){
            this.$store.commit('set-loader-active', true)

            fetch(`${this.$hostname}/devices/set-control`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.token
                },
                body: JSON.stringify({
                    deviceId: this.activeDeviceId,
                    valve: this.valve,
                    heating: this.heating,
                    backrunning: this.backrunning
                })
            }).then(async e => {

                if (e.status == 200) {
                    const { error } = await e.json()
                    if(error)
                        console.log(error);
                } else if (e.status == 403) {
                    this.$router.push({ name: 'start-view ' })
                }
            }).catch(e => {
                console.log(e)
                this.loaderActive = false

            })
        },
        forceCloseLoader(){
            this.$store.commit('set-loader-active', false)

        }
    },
    data(){
        return{
            valve: 'Auto',
            heating: 'Auto',
            backrunning: 'Auto',

        }
    }
}
</script>