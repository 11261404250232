<template>
      <div class="vertical-wrapper" style="overflow: scroll; padding: 0 10pt;">
        <div v-if="noActiveDevice" class="vertical-wrapper">
            <div></div>
            <h2 style="color: #999">Kein Gerät hinzugefügt</h2>
            <div>
                <md-button class="md-raised md-primary"  v-on:click="$router.push({ name: 'device-add-view' })">hinzugefügen</md-button>
            </div>
            <div></div>

        </div>
        <div v-else class="vertical-wrapper">
            <div class="vertical-wrapper" style="flex: 0 0 auto">
                <h3>Tank Einstellungen</h3>
                <md-field style="flex: 0 0 auto">
                    <label for="">Tankvolumen in [l]</label>
                    <md-input type="number" v-model="tankSize" @blur="saveTankParameters"></md-input>
                    <span class="md-suffix">liter</span>
                </md-field>

                <md-field style="flex: 0 0 auto">
                    <label for="">Befüllung Start [h]</label>
                    <md-input type="number" v-model="startFilling" @blur="saveTankParameters"></md-input>
                    <span class="md-suffix">Stunden</span>
                </md-field>
            </div>
            
            <div v-if="getDisplayHeating" class="vertical-wrapper"  style="flex: 0 0 auto">
                <h3>Heizungs Einstellungen</h3>

                <md-field style="flex: 0 0 auto">
                    <label for="">Hysterese [+/- °C]</label>
                    <md-input type="number" v-model="heatingHysteresis" @blur="saveTankParameters"></md-input>
                    <span class="md-suffix">+/- °C</span>
                </md-field>
                <md-field style="flex: 0 0 auto">
                    <label for="">Heizung einschalten ab [%]</label>
                    <md-input type="number" v-model="startHeating" @blur="saveTankParameters"></md-input>
                    <span class="md-suffix">%</span>
                </md-field>
            </div>
            

        
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'


export default {
    name: 'tank-view-settings',
    computed:{
        // filling parameters
        tankSize:{
            set(v){
                this.valueHasChanged = true;

                this.$store.getters.getTankParameters['tankSize'] = parseFloat(v)
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get(){
                return this.$store.getters.getTankParameters['tankSize']
            }

        },
        startFilling: {
            set(v) {
                this.valueHasChanged = true;

                this.$store.getters.getTankParameters['startFilling'] = parseFloat(v)
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get() {
                return this.$store.getters.getTankParameters['startFilling']
            }

        },
        maxFillTime: {
            set(v) {
                this.valueHasChanged = true;

                this.$store.getters.getTankParameters['maxFillTime'] = parseFloat(v)
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get() {
                return this.$store.getters.getTankParameters['maxFillTime']
            }

        },
       
        // heating parameter
        heatingHysteresis: {
            set(v) {
                this.valueHasChanged = true;

                this.$store.getters.getTankParameters['heatingHysteresis'] = parseFloat(v)
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get() {
                return this.$store.getters.getTankParameters['heatingHysteresis']
            }
        },
        startHeating: {
            set(v) {
                this.valueHasChanged = true;

                this.$store.getters.getTankParameters['startHeating'] = parseFloat(v)
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get() {
                return this.$store.getters.getTankParameters['startHeating']
            }
        },

        tankParameters(){
            return this.$store.getters.getTankParameters
        },
        token() {
            return this.$store.getters.token
        },
        activeDeviceId() {
            return this.$store.getters.getActiveDeviceId
        },
        ...mapGetters([
            'noActiveDevice',
            'getDisplayHeating'
        ])
    },
    methods: {
        saveTankParameters(){
            if(this.valueHasChanged){
                fetch(`${this.$hostname}/devices/edit`, {
                    method: 'POST',
                    headers: { 
                        "Content-Type": "application/json",
                        "Authorization": this.token 
                    },
                    body: JSON.stringify({
                        name: undefined,
                        deviceId: this.activeDeviceId,
                        parameter: this.tankParameters 
                    })
                }).then(async e => {
                    const { error } = await e.json()
                    if(e.status == 200){
                        console.log(error);
                    } else if (e.status == 403) {
                        this.$router.push({ name: 'start-view ' })
                    }
                })
            }
        }
    },  
    data() {
        return {
            valueHasChanged: false
        }
    }
}
</script>