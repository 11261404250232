<template>
    <div>
        <md-dialog :md-active="show">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Wifi Passwort</md-dialog-title>
        
                <md-dialog-actions>
                    <md-button class="md-primary" v-on:click="$router.replace({ name: 'device-add-view-wifi' })">Close</md-button>
                    <md-button class="md-accent md-raised" v-on:click="connect">Verbinden</md-button>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="margin: 0pt 24px ">
                <p>Verbinden mit Wifi</p>
                <h3 style="margin-top: 0">{{ssid}}</h3>
                <md-field class="md-accent">
                    <label for="">Passwort</label>
                    <md-input type="password" v-model="password"></md-input>
                </md-field>
                <md-progress-bar v-if="connecting" class="md-accent" md-mode="indeterminate" style="flex: 0 0 2pt"></md-progress-bar>
            </div>
        </md-dialog>
    </div>
</template>
<script>
import swivel from 'swivel'


export default {
    name: 'device-connector-wifi-password',
    props: { ssid: String},
    methods:{
        connect(){
            this.connecting = true;
            const vm = this;

            navigator.serviceWorker
                .register('/service-worker.js')
                .then(navigator.serviceWorker.ready)
                .then(function () {
                    swivel.on('set-wifi-response', function handler(context, data) {
                        // do something with ...data
                        const text = data;

                        if (text.includes('Success')) {
                            this.connecting = false;
                            vm.$swal.fire({ icon: 'success', title: 'Verbunden', html:  text, showConfirmButton: true, confirmButtonText: 'Ok' }).then(() => {

                                vm.$router.push({ name: 'menu-devices-view' })
                            })

                        } else {
                            vm.$swal.fire({ icon: 'error', title: 'Nicht Verbunden',  html:  text })
                        }

                    });

                    swivel.emit('set-wifi', {ssid: vm.ssid, password: vm.password})
                });



            /*const url = `http://192.168.4.1/wifi-connect?ssid=${encodeURIComponent(this.ssid)}&passphrase=${encodeURIComponent(this.password)}`

            fetch(url, { referrerPolicy: "no-referrer", })
            .then(e => e.text())
            .then(e => {
                this.connecting = false

                if (e.includes('Success')){
                    this.$swal.fire({ icon: 'success',  title: 'Verbunden', text: e , showConfirmButton: true, confirmButtonText: 'Ok'}).then(() => {
                        this.$router.push({ name: 'menu-devices-view'})
                    })

                }else{
                    this.$swal.fire({ icon: 'error', title: 'Nicht Verbunden', text: e })
                }

            }).catch(e => console.log(e)).catch(e => console.log(e))
            */
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.show = true
        })
    },
    data(){
        return{
            show: false,

            password: '',

            connecting: false,
        }
    }
}
</script>