<template>
    <div class="horizontal-wrapper" style="flex: 0 0 auto">
        <md-field style="flex: 1 0 0">
            <label for="">{{name}}</label>
            <md-input v-model="sensorBarcode" @blur="$emit('blur')"></md-input>
        </md-field>
        <div v-ripple style="flex: 0 0 30pt; margin: 10pt" class="icon" :style="`background-image: url(${require('../assets/barcode-solid.svg')})`" v-on:click="show = true">

        </div>

        <md-dialog :md-active="show">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Barcode Scanner</md-dialog-title>
                <md-dialog-actions>
                    <md-button class="md-primary md-raised" v-on:click="show = false">Close</md-button>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="margin: 0pt 24px;">
                <h2>{{name}}</h2>
                <div></div>
                <qrcode-stream @detect="onDetect"></qrcode-stream>
                <h3>{{qrCodeRead}}</h3>
                <div></div>
            </div>
        </md-dialog>
    </div>
</template>
<script>
import { QrcodeStream      } from 'vue-qrcode-reader'

export default {
    name: 'sensor-input',
    props: { 'name': String, 'value': [String, Number] },
    components: {
        QrcodeStream,
    },
    computed: {
        sensorBarcode:{

            get(){
                return this.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    },
    methods:{
        onDetect(detectedCodes){
            this.qrCodeRead = detectedCodes
        }
    },
    data(){
        return{
            show: false,

            qrCodeRead: '',
        }
    }
}
</script>