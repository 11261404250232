<template>
    <div class="vertical-wrapper" style="flex: 0 0 auto">
        <h4 style="margin-bottom: 2pt">Füllmenge</h4>
        <md-field style="flex: 1 0 0; margin-bottom: 10pt">
            <label for="">Menge [l]</label>
            <md-input  type="number" style="width: 100%" v-model="amountValue"></md-input>
            <span  class="md-suffix">Liter</span>
        </md-field>

        <div v-if="displayHeating" style="flex: 0 0 auto" class="vertical-wrapper">
            <div class="vertical-wrapper" style="flex: 0 0 auto; margin-bottom: 5pt">
                <div class="horizontal-wrapper">
                    <md-switch class="md-primary"  style="margin: auto 0; flex: 0 0 auto" v-model="heatingValue">Aufheizen</md-switch>

                </div>
                <md-field v-if="heating" style="flex: 0 0 auto; margin-bottom: 5pt">
                    <label for="">Zieltemepratur</label>
                    <md-input  type="number" style="width: 100%" v-model="temperaturValue"></md-input>
                    <span class="md-suffix">°C</span>
                </md-field>
            </div>
                  
        </div>
        <div v-else style="flex: 0 0 auto" class="vertical-wrapper hide">
            <md-switch class="md-primary"  style="flex: 0 0 auto" v-model="heatingValue">Freigabe Aufheizen</md-switch>
        </div>
    </div>
</template>
<script>

export default {
    name: 'date-header',
    props: { amount: [Number, String], heating:Boolean, temperatur: [Number, String], hysterese:  [Number, String] },
    computed:{
        hasTemperaturSensor(){
            return this.$store.getters.hasTemperaturSensor
        },
        amountValue:{
            get(){
                return this.$props.amount
            },
            set(v){
                this.$emit('update:amount', v)
            }
        },
        heatingValue: {
            get() {
                return this.$props.heating
            },
            set(v) {
                this.$emit('update:heating', v)
            }
        },
        temperaturValue: {
            get() {
                return this.$props.temperatur
            },
            set(v) {
                this.$emit('update:temperatur', v)
            }
        },
        hystereseValue: {
            get() {
                return this.$props.hysterese
            },
            set(v) {
                this.$emit('update:hysterese', v)
            }
        },
        maxTankVolume() {
            return parseFloat(this.$store.getters.getTankParameters.size)
        },
        amountPercent:{
            get(){
                return (( parseFloat(this.amountValue) / this.maxTankVolume ) * 100 ).toFixed(0)
            },
            set(v) {
               this.amountValue = (this.maxTankVolume * (v / 100)).toFixed(2)
            }
        },
        displayHeating(){
            return this.$store.getters.getDisplayHeating
        }
        
    },
    methods:{
        changeDate(days){
            this.$store.commit('set-date', this.$moment(this.date).add(days, 'days'))
            this.$emit('change')
        }
    },
    data(){
        return {
        }
    } 

}
</script>