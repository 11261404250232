<template>
    <div class="vertical-wrapper">
        <router-view style="flex: 1 0 0">

        </router-view>

    

        <md-bottom-bar style="flex: 0 0 auto; z-index:10; padding-bottom: env(safe-area-inset-bottom);" class="footer inverted" md-type="fixed" :md-sync-route="true"
            ref="bottom-navigator" :options="{}">

            <md-bottom-bar-item id="tank" :to="{ name: 'tank-view' }"
                :md-icon="require('../assets/menu-bar/tank-solid.svg')"></md-bottom-bar-item>

            <md-bottom-bar-item id="tank-settings" :to="{ name: 'settings-view' }"
                :md-icon="require('../assets/menu-bar/gear-solid.svg')"></md-bottom-bar-item>


            <md-bottom-bar-item id="settings" :to="{ name: 'menu-view' }"
                :md-icon="require('../assets/menu-bar/bars-solid.svg')"></md-bottom-bar-item>


        </md-bottom-bar>
    </div>
</template>
<script>
export default {
    name: 'home-view',
    computed:{
        hasTemperaturSensor() {
            return this.$store.getters.hasTemperaturSensor
        },
       
    }
}
</script>