<template>
    <div>
        <md-dialog :md-active="show">
            <div class="horizontal-wrapper hide" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline; margin-bottom: 0">Aufgabe</md-dialog-title>

                <md-dialog-actions>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="overflow-y: scroll">
                 <div class="vertical-wrapper" style="margin: 0pt 24px; margin-top: 28px; flex: 0 0 auto">
                    <div class="horizontal-wrapper" style="flex: 0 0 auto">
                        <md-field style="flex: 3 0 0;">
                            <label for="">Aufgaben Bezeichnung</label>
                            <md-input v-model="name"></md-input>
                        </md-field>
                    </div>
                </div>
                <div style="margin: 0pt 24px ">
                    <filling-control 
                        :amount.sync="amount" 
                        :heating.sync="heating" 
                        :temperatur.sync="temperatur"
                        :hysterese.sync="hysterese"
                        >

                    </filling-control>
                </div>
            
                <span style="margin: 0pt 24px ">Start um {{ nextStart }}</span>
                <div style="margin: 0pt 24px ">
                    <h4 style="margin-bottom: 2pt">Wiederholung</h4>

                    <md-field>
                        <label for="">Wiederholung</label>
                        <md-select v-model="repeat">
                            <md-option :value="0">einmalig</md-option>
                            <md-option :value="1">täglich</md-option>
                            <md-option :value="2">wöchentlich</md-option>
                        </md-select>
                    </md-field>

                    <div>
                        <div class="vertical-wrapper" v-if="repeat == 0">
                            Fülldatum
                            <div class="horizontal-wrapper">
                                <vue-datetime 
                                    style="flex: 1 0 0; margin: 5pt 0pt;" 
                                    type="datetime" 
                                    ref="datetimepicker" 
                                    class="flex-datetimepicker theme-green" 
                                    :min-datetime="isoFormatedNow" 
                                    v-model="isoFormatedDate" >
                                </vue-datetime>
                            </div>
                        
                        </div>  
                    
                        <div v-if="repeat == 1">
                            Uhrzeit
                            <vue-datetime 
                                style="flex: 0 0 0" 
                                type="time" 
                                ref="datetimepicker" 
                                class="flex-datetimepicker theme-green" 
                                title="Uhrzeit"
                                v-model="isoFormatedDate">
                            </vue-datetime>
                        </div>


                        <div v-if="repeat == 2">
                            Wochentage
                            <div class="horizontal-wrapper">
                                <md-chip v-for="chip in weekdays" :key="chip.key" v-on:click="chip.active = !chip.active" style="text-align: center;" :class="{ 'md-primary': chip.active }" >{{ chip.text }}</md-chip>
                            </div>
                            <div style="margin-top: 20px">
                                Uhrzeit
                                <vue-datetime 
                                    style="flex: 0 0 0; margin: auto" 
                                    type="time" 
                                    title="Uhrzeit" 
                                    class="flex-datetimepicker theme-green" 
                                    v-model="isoFormatedDate">
                                </vue-datetime>
                            </div>
                        </div>

                        
                    </div>
                    <div></div>
              
                    <!--Time Selector--> 
                </div>
            
                <div class="vertical-wrapper" style="margin: 0pt 24px; margin-top: 20px; flex: 0 0 auto">
                    <md-button class="md-primary md-raised" style="margin: 10pt 0; margin-top: 5pt" v-on:click="save">

                         <div class="horizontal-wrapper" style="gap: 5pt">
                            <md-icon :md-src="require('../../assets/floppy-disk-solid.svg')" class="icon-fill-white"></md-icon>
                            <p>
                                {{ fillingWillStartImidiatly ? 'Jetzt Starten' : 'Speichern' }}
                            </p>
                        </div>
                    </md-button>

                    <div class="horizontal-wrapper" style="gap: 5pt">
                        <md-button v-if="edit" class="md-raised" style="margin: 10pt 0 ; flex: 1 0 0; margin-bottom: 5pt" v-on:click="deleteTask">
                            <div class="horizontal-wrapper" style="gap: 5pt">
                                <md-icon :md-src="require('../../assets/trash-can-solid.svg')"></md-icon>
                                <p>
                                    Löschen
                                </p>
                            </div>
                        </md-button>

                        <md-button class="md-raised" style="margin: 10pt 0 ; flex: 1 0 0; margin-bottom: 5pt" v-on:click="close">
                            <div class="horizontal-wrapper" style="gap: 5pt">
                                <md-icon :md-src="require('../../assets/xmark-solid.svg')"></md-icon>
                                <p>
                                    Schließen
                                </p>
                            </div>
                        </md-button>
                    </div>

                </div>
            </div>
           
        </md-dialog>
    </div>
</template>
<script>
import fillingControlVue from '@/components/filling-control.vue'
export default {
    name: 'task-popup',
    props: { taskId:String },
    
    components: {
        'filling-control':fillingControlVue
    },
    methods: {
        async save() {
            if(this.amount <= 0){
                this.$swal.fire({ 
                    icon: 'warning', 
                    title: 'Achtung', 
                    text: 'Keine Füllmenge vorgegeben',
                })
                return
            }
            else if (this.amount > this.tankParameters.size){
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Achtung',
                    text: 'Die eingegebene Füllmenge überschreitet maximales Tankvolumen',
                })

                return
            }

            const task = {
                repeat: this.repeat,
                name: this.name,
                amount: this.amount,

                heating: this.heating,
                temperatur: this.temperatur
            }
            if( this.repeat == 0){
                task.date = this.date.format('YYYY-MM-DD HH:mm')
                
                // if the task start immidiatly add 30sec for Syncronisation
                if(this.fillingWillStartImidiatly)
                    task.date = this.date.add(30, 'seconds').format('YYYY-MM-DD HH:mm')
                // if date hasnt changed override it
                if(!this.dateChanged){
                    task.date = this.$moment().add(30, 'seconds').format('YYYY-MM-DD HH:mm')
                }
            }
            // if repeat daily
            else if(this.repeat == 1)
                task.time = this.date.format('HH:mm')
            // weekly repeat
            else if (this.repeat == 2){
                task.time = this.date.format('HH:mm')
                task.weekdays = this.weekdays.map((e, i) => e.active? i: -1 ).filter(e => e >= 0)
            }
            console.log(task);
            this.$store.dispatch('save-task', [this.taskId, task])
        },

        close(){
            this.$swal.fire({ 
                icon: 'question', 
                title: 'Verlassen?', 
                text: 'Sicher ohne Speichern verlassen?',
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                showDenyButton: true,
                denyButtonText: 'Nein',

            }).then(({isConfirmed}) => {
                if(isConfirmed)
                    this.$router.replace({ name: 'tank-repeat-view' })
            })
        },

        async deleteTask(){
            const { isConfirmed } = await this.$swal.fire({
                icon: 'question',
                title: 'Löschen?',
                text: 'Möchtest du die Aufgabe wirklich löschen?',

                showConfirmButton: true,
                confirmButtonText: 'Löschen',

                showDenyButton: true,
                denyButtonText: 'Abbrechen'
            })

            if (!isConfirmed) {
                return
            }

            //
            // Send Delete Task
            // 

            fetch(`${this.$hostname}/tasks/delete`, {
                method: 'Post',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.token
                },
                body: JSON.stringify({ taskId: this.taskId })
            }).then(async e => {
                if (e.status == 200) {
                    //const state = await e.json()
                    //if (state) {
                        // console.log(state);
                    //}
                }
               // this.$store.dispatch('sync-tasks')
                this.$router.push({ name: 'tank-view' })
            }).catch().catch()
        }
    },
    computed:{
        isoFormatedDate: {
            set(e) {
                this.date = this.$moment(e)

            },
            get() {
                return this.date.format('YYYY-MM-DDTHH:mm:ssZ')
            }
        },
        isoFormatedNow(){
              return this.$moment().format('YYYY-MM-DDTHH:mm:ssZ')
        },
        tasks(){
            return this.$store.getters.getTasks
        },
        hasTemperaturSensor(){
            return this.$store.getters.hasTemperaturSensor
        },

        fillingWillStartImidiatly(){
            const startFilling = this.$store.getters.getTankParameters.startFilling;

            return this.repeat == 0? (this.$moment().valueOf() + startFilling * 3600) > this.date.valueOf() : false
        },
        tankParameters() {
            return this.$store.getters.getTankParameters
        },
        token() {
            return this.$store.getters.token
        },
        displayHeating(){
            return this.$store.getters.getDisplayHeating
        },
        nextStart(){
            const startFilling = this.$store.getters.getTankParameters.startFilling;
            
            if(this.repeat == 0)
                return this.$moment(this.date).add(startFilling * -1, 'hours').format('YYYY-MM-DD HH:mm');
            // daily repeat
            else if (this.repeat == 1){
                const now = this.$moment()

                const startTime = this.$moment(this.date)
                // check if today time is larger than next time
                // starting offset needs to be added later
                if(now.format('HH:mm') > startTime.format('HH:mm'))
                    startTime.add(1, 'days')

                // add now the start offset
                return `${startTime.add(startFilling * -1, 'hours').format('YYYY-MM-DD HH:mm')}`
            }
            //weekly repeate
            else if (this.repeat == 2){
                const date = this.$moment(this.date)
                const timeNow = this.$moment()

                const currentWeekDay = timeNow.weekday()
 
                for(let i = currentWeekDay; i < currentWeekDay + 7; i++){
                    const weekdayIndex = i % 7
                    // when weekday is active, and week day is not current weekday or selected time is after now
                    if(this.weekdays[weekdayIndex].active && (weekdayIndex || date.format('HH:mm') >= timeNow.format('HH:mm')))
                        break
                    // add one date
                    date.add(1, 'days')

                }
                return `${date.add(startFilling * -1, 'hours').format('YYYY-MM-DD HH:mm')}`
            }
            return 'Error'
        },

        taskPopupOpen(){
            return this.$store.getters.getTaskPopupOpen
        },
        taskPopupError(){
            return this.$store.getters.getTaskPopupError
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.show = true
            vm.$store.commit('set-task-popup-open', true)

            const task = vm.tasks.find(e => e.taskId == vm.taskId)
            if(task){
                vm.edit = true
                vm.name = task.name;
                vm.amount = task.amount;
                vm.repeat = task.repeat;

                //heating profile
                vm.heating = task.heating;
                vm.temperatur = task.temperatur;
                vm.hysterese = task.hysterese;

                if (vm.repeat == 0)
                    vm.date = vm.$moment(task.date)
                // if repeat daily
                else if (vm.repeat == 1){
                    vm.date = vm.$moment(`${vm.$moment().format('YYYY-MM-DD')} ${task.time}`)
                    //vm.date.setTIme
                }

                // weekly repeat
                else if (vm.repeat == 2) {
                    vm.date = vm.$moment(`${vm.$moment().format('YYYY-MM-DD')} ${task.time}`)
                    vm.weekdays.forEach((e, i) => e.active = task.weekdays.includes(i))
                }
                  vm.dateChanged = true
                
            }else{
                vm.edit = false
                vm.dateChanged = false
                vm.$nextTick(() => {
                    vm.dateChanged = false
                })

            }
           
        })
    },
    watch:{
        date(){
            this.dateChanged = true
        },
     
        taskPopupError(){
            if(this.taskPopupError){
                this.$swal.fire({ 
                    icon: 'warning', 
                    title: 'Achtung', 
                    text: this.taskPopupError ,
                })
            }
        }
    },
    data() {
        const days = ['M', 'D', 'M', 'D', 'F', 'S', 'S'];

        const monthDays = Array(31).fill(0).map(()=> {return { key: this.$uuid.v4(), text: 'M', active: false }})
        monthDays.forEach((e, i) => e.text = days[i % 7])

        return {
            edit: false,
            show: false,

            name: '',
            repeat: 0,
            
            weekdays: [
                { key: this.$uuid.v4(), text: 'M', active: false },
                { key: this.$uuid.v4(), text: 'D', active: false },
                { key: this.$uuid.v4(), text: 'M', active: false },
                { key: this.$uuid.v4(), text: 'D', active: false },
                { key: this.$uuid.v4(), text: 'F', active: false },
                { key: this.$uuid.v4(), text: 'S', active: false },
                { key: this.$uuid.v4(), text: 'S', active: false },

            ],
            monthDays: monthDays,

            amount: 0,
            date: this.$moment(),
            dateChanged: false,
            time: '00:00',

            heating: false,
            temperatur: 90,
            hysterese: 3
        }
    }
}
</script>
<style>
.flex-datetimepicker input{
    width: 100%;
    text-align: center;

    font-size: 18pt;
}
</style>