<template>
    <div>
        <h2>Login via url</h2>
         <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>
</template>
<script>
export default {
    name: 'login-via-url',
    props: { token: String },
    beforeRouteEnter(to, from, next){
        next(vm => {
            const token = vm.token
            fetch(`${vm.$hostname}/user/login-via-url`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token
                })
            }).then(async e => {
                if (e.status == 200) {
                    const { error, authendification } = await e.json()
                    if (error) {
                        vm.$swal.fire({ title: 'Fehler', icon: 'error', text: error })
                    } else if (authendification){
                        vm.$store.commit('set-token', authendification)
                        vm.$router.push({ name: 'home' })
                    }
                       

                }
            })
            

        })
    }
}
</script>