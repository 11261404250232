
import Vue from 'vue'
import Vuex from 'vuex'

import { getNextDate } from '../utils/modules.js'
const moment = require('moment')
import router from '@/router'

Vue.use(Vuex)

const store = function createStore() {
    let userToken = Vue.prototype.$cookies.get('authentication')
    const hostname = Vue.prototype.$hostname;

    if (!userToken)
        userToken = ''

    return new Vuex.Store({
        state: () => ({
            token: userToken,
            user: { id: -1, name: '', email: '' },

            admin: 0,

            settings: {
                temperaturSensor: true,
            },
            activeDevice: 0, 
            noActiveDevice: false,

            deviceCommunicationError: false,
            status: {
                connected:false, 
                filling: false, 
                heating: false,
                
                flow: 0,
                temperatur: 0,
                amountInQueue: 0,

                inProgress: [],

                rpsError: false,
                vfsError: false,
                overFlowError: false,

                rtc_now: 0



            },

            tasks: [],
            tasksHash: '',
            taskPopupOpen: false,
            taskPopupError: false,

            tankParameters: { tankSize: 100.0, maxFillTime: 30, startFilling: 10, fillingSpeed: 2.5, timezone: 'Europe/Berlin'},

            loaderActive: 0

        }),
        mutations: {
            'set-token': function (state, token) {
                Vue.prototype.$cookies.set('authentication', token)
                state.token = token;

            },
            'set-admin': function (state, admin) {
                state.admin = admin
            },
            'set-user': function(state, {name, email}){
                if(name)
                    state.user.name = name;
                if(email)
                    state.user.email = email;

            },
            'clear-task': function(state){
                state.tasks = []
            },
            'edit-task': function (state, [taskId, task]) {
                const m = state.tasks.find(e => e.taskId == taskId)

                // if task exists and is to sync stop with mutation
                if (m && m.toSync)
                    return

                task.nextDate = getNextDate(task)
                const copyTasks = state.tasks.filter(e => e.taskId != taskId)
                state.tasks = copyTasks
                state.tasks.push(task)
                state.tasks.sort((a, b) => moment(a.nextDate).valueOf() - moment(b.nextDate).valueOf())

            },
            'set-task': function(state, [taskId, task]){
                task.nextDate = getNextDate(task)


                const copyTasks = state.tasks.filter(e => e.taskId != taskId)
                state.tasks = copyTasks
                state.tasks.push(task)

            },
            'set-active-device': function(state, device){
                state.activeDevice = device
                state.noActiveDevice = false;
            },
            'set-tank-parameters': function(state, tankParameters){
                state.tankParameters = tankParameters
            },
            'set-no-active-device': function(state, value){
                state.noActiveDevice = value
            },
            'set-device-state': function(state, status){
                state.status = status
            },
            'set-device-communication': function(state, err){
                state.deviceCommunicationError = err
            },
            'set-loader-active': function(state, value){
                if(value)
                    state.loaderActive = 2
                else
                    state.loaderActive--

            },
            'set-task-popup-open': function(state, value){
                state.taskPopupOpen = value
            },
            'set-task-popup-error': function(state, text){
                state.taskPopupError = text
            }
          
        },
        actions: {
            
            'fetch-active-device': function ({ commit }){
                fetch(`${hostname}/devices/get-active`, {
                    method: 'POST',
                    headers: { "Authorization": this.state.token },
                }).then(async e => {
                    if(e.status == 200){
                        const { device, error } = await e.json()
                        if(device){
                            commit('set-active-device', device)
                        
                            commit('set-tank-parameters', device.parameter) 
                            
                        }else if (error){
                            commit('set-no-active-device', true)
                            Vue.prototype.$deviceConnector ? Vue.prototype.$deviceConnector.add(): 0
                        }
                    }
                    else if (e.status == 403){ // authendification error
                        
                        router.push({ name: 'start-view' })
                    }
                }).catch().catch()
            },
            'sync-tasks':function({ commit }){
                fetch(`${hostname}/tasks/get`, {
                    method: 'POST',
                    headers: { "Authorization": this.state.token },
                }).then(async e => {
                    if(e.status == 200){
                        const { tasks, error } = await e.json()
                        if(tasks){
                            commit('clear-task')
                            tasks.forEach(e => { 
                                e.toSync = false
                                commit('edit-task', [e.taskId, e]) 
                            })
                            commit('set-task-popup-open', false)

                            return
                        }else if(error){
                            commit('set-task-popup-error', error)

                            console.log();

                        }

                    }
                    else if (e.status == 403){ // authendification error
                        
                        router.push({ name: 'start-view' })
                    }
                }).catch().catch()

            },
            'save-task': function ({ commit, state }, [taskId, task]){
                // Set Task ID to no sync
                if (state.tasks[taskId])
                    state.tasks[taskId].toSync = true;

                // set to local task id
                task.taskId = taskId
                try{
                    fetch(`${hostname}/tasks/edit`, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": this.state.token
                        },
                        body: JSON.stringify({ task })
                    }).then(async e => {
                        if (e.status == 200) {
                            const { taskId, error } = await e.json();
                            if (taskId) {
                                task.toSync = false;
                                commit('set-task', [taskId, task])

                                commit('set-task-popup-error', false)
                                // console.log( 'tank-repeat-view');
                                router.push({ name: 'tank-repeat-view' })

                            } else if (error) {
                                commit('set-task-popup-error', error)

                                // console.log(error);
                            }
                        }
                        else if (e.status == 403){ // authendification error
                        
                            router.push({ name: 'start-view' })
                        }

                    }).catch().catch()
                }catch(e){
                    console.log(e);
                }
               
            },

            'fetch-user': function({ commit }){
                fetch(`${hostname}/user/read`, {
                    method: 'POST',
                    headers: { "Authorization": this.state.token }, 
                }).then( async e => {
                    if(e.status == 200){
                        const {user, error} = await e.json()
                        
                        if(user)
                            commit('set-user', user)
                        else if(error)
                            console.log(error);
                    }
                })
            },

            'fetch-system-state': function({ commit }){
                fetch(`${hostname}/devices/get-state`, {
                    method: 'POST',
                    headers: { "Authorization": this.state.token },
                }).then(async e => {
                    if (e.status == 200) {
                        const  state = await e.json()
                        if(state){
                           // console.log(state);
                            commit('set-device-communication', false)
                            
                            commit('set-device-state', state)

                            commit('set-loader-active', false)

                        }
                    } else if (e.status == 403){ // authendification error
                        
                        router.push({ name: 'start-view' })
                    } else {
                        commit('set-device-communication', true)

                    }
                }).catch().catch()
            },


        },
        getters: {
            token(state) {
                return state.token;
            },

            isadmin(state) {
                return state.admin
            },
            getSystemStatus(state){
                return state.status
            },
            getSystemRTC(state){
                return state.status.rtc_now
            },


            getTasks(state) {
                // sort date after next date
                const tasks = state.tasks;

                tasks.sort((a, b) => moment(a.nextDate).valueOf() - moment(b.nextDate).valueOf())
                
                tasks.forEach(t => {
                    // task is considerd active if it has a repeating 
                    // or if nextDate minus fillting start is in the future
                    //const startPoint = moment(t.nextDate).valueOf() - state.tankParameters.startFilling * 3600 * 1000
                    const endPoint = moment(t.nextDate).valueOf() + 5 * 60 * 1000

                    //t.inProgress = startPoint <= moment().valueOf() && moment().valueOf() <= endPoint 
                    t.inProgress = state.status.inProgress.some(e => e == t.taskId)
                    
                    t.active = t.repeat > 0 ? true : (moment().valueOf() <= endPoint )

                    //console.log(t.active, t.inProgress, t.nextDate, startPoint <=  moment().valueOf(), moment().valueOf() <= endPoint  , moment(endPoint).format('YYYY-MM-DD HH:mm:ss'))
                })

                return tasks;
            },
           
            getTasksHash(state){
                return state.tasksHash
            },
            
            getTankParameters(state){

                return state.tankParameters
            },
            getActiveDeviceId(state){
                return state.activeDevice.deviceId
            },
            getActiveDevice(state){
                return state.activeDevice
            },
            hasTemperaturSensor(state){
                return state.settings.temperaturSensor
            },
            

            getUser(state){
                return state.user
            },
            noActiveDevice(state){
                return state.noActiveDevice;
            },
            getDeviceCommunicationError(state){
                return state.deviceCommunicationError
            },

            getDisplayHeating(state){
                return state.tankParameters.rps != '0'
            },
            getLoaderActive(state){
                return state.loaderActive > 0
            },

            getTaskPopupOpen(state){
                return state.taskPopupOpen
            },

            getTaskPopupError(state){
                return state.taskPopupError
            }
        }
    })
}
export default store;