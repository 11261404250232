<template>
  <div id="app" class="vertical-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: calc(100vh - env(safe-area-inset-bottom));
}
.md-dialog-container.md-dialog-fullscreen{
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
