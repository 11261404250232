<template>
    <div>
        <div class="vertical-wrapper" style="margin: 5pt">

            <md-field>
                <label for="">Username</label>
                <md-input v-model="username" v-on:keyup.enter="login"></md-input>
            </md-field>
            <md-field>
                <label for="">Password</label>
                <md-input type="password" v-model="pw" v-on:keyup.enter="login"></md-input>
            </md-field>
            <div class="vertical-wrapper" style="flex: 0 0 30pt">
                <div></div>
                <router-link :to="{name:'forgot-password-view'}">Passwort vergessen</router-link>
                <div></div>
            </div>
            <md-button class="md-primary md-raised" style="margin: 0" v-on:click="login">Login</md-button>
        </div>


    </div>
</template>
<script>
export default {
    name: 'login-view',
    methods:{
        login(){
            this.loginAtempt = true;
            fetch(`${this.$hostname}/user/login`, { 
                method: 'POST', 
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ 
                    name: this.username, 
                    password: this.pw
                })
                }).then(async e => {
                    if(e.status == 200){
                        const { authendification, error } = await e.json()
                        if (authendification){
                            this.$store.commit('set-token', authendification)
                            this.$router.push({name : 'home'})
                        }
                        else if (error)
                            this.$swal.fire({ title: 'Login fehlgeschlagen', text: 'Falsche Logindaten', icon: 'error' })
                    }
            })
        }
    },
    
    sockets:{
       
        'login-failed': function () {
            if(!this.loginAtempt)
                return

            this.$swal({
                icon: 'error',
                title: 'Login failed',
                text: 'wrong login creditals',
            })
        },
    },
    data(){
        return{
            mandates: [],
            selectedMandate: 0,
            
            loginAtempt: false,
            username: '', 
            pw: ''
        }
    }
}
</script>