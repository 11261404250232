<template>
    <div>
        <md-dialog :md-active="show">
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline; margin-bottom: 0">Aufgaben Archiv</md-dialog-title>

                <md-dialog-actions>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" >
                <div style="flex: 1 0 0; overflow-y: scroll; margin: 24px 0; padding: 0 24px; gap: 10px" >
                    <h3 v-if="!archive.length" style="color: #aaa">keine Einträge vorhanden</h3>

                    <div v-for="e in archive" :key="e.key" class="vertical-wrapper" style="box-shadow: #ccc 0pt 0pt 2pt 1pt; padding: 2px; margin: 10px 0;" >
                        <span>{{e.name}}</span>

                        <div class="horizontal-wrapper">
                            <div class="vertical-wrapper" style="flex: 2 0 0" >

                                <div class="horizontal-wrapper" style="gap: 5pt">
                                    <span>Start:</span>
                                    <span>{{ $moment(e.start).format('YYYY-MM-DD HH:mm') }}</span>
                                </div>
                                <div class="horizontal-wrapper" style="gap: 5pt">
                                    <span>Ende:</span>
                                    <span>{{ e.end? $moment(e.end).format('YYYY-MM-DD HH:mm') : 'läuft' }}</span>
                                    
                                </div>

                            </div>
                            <div class="vertical-wrapper">
                                <div class="horizontal-wrapper" style="gap: 5pt">
                                    <span>befüllt:</span>
                                    <span>{{ e.amount }} l</span>
                                </div>
                                <div class="horizontal-wrapper" style="gap: 5pt">
                                    <span>beheizt:</span>
                                    <span>{{ e.temperatur }} °C</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <md-button class="md-raised" style="margin: 0pt 24px; flex: 0 0 auto; margin-bottom: 5pt" v-on:click="close">
                    <div class="horizontal-wrapper" style="gap: 5pt">
                        <md-icon :md-src="require('../../assets/xmark-solid.svg')"></md-icon>
                        <p>Schließen</p>
                    </div>  
                </md-button>
                
            </div>
           
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'task-archive-popup',
    
    methods: {
  
        close(){
          this.$router.go(-1)
        },

    },
    computed:{
        isoFormatedDate: {
            set(e) {
                this.date = this.$moment(e)

            },
            get() {
                return this.date.format('YYYY-MM-DDTHH:mm:ssZ')
            }
        },
        isoFormatedNow(){
              return this.$moment().format('YYYY-MM-DDTHH:mm:ssZ')
        },
        tasks(){
            return this.$store.getters.getTasks
        },
        hasTemperaturSensor(){
            return this.$store.getters.hasTemperaturSensor
        },

        fillingWillStartImidiatly(){
            const startFilling = this.$store.getters.getTankParameters.startFilling;

            return this.repeat == 0? (this.$moment().valueOf() + startFilling * 3600) > this.date.valueOf() : false
        },
        tankParameters() {
            return this.$store.getters.getTankParameters
        },
        token() {
            return this.$store.getters.token
        },
        displayHeating(){
            return this.$store.getters.getDisplayHeating
        },

        
      

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.show = true;



            fetch(`${vm.$hostname}/devices/get-archive`, {
                    method: 'POST',
                    headers: { 
                        "Content-Type": "application/json",
                        "Authorization": vm.token 
                    },
                    body: JSON.stringify({
                        
                    })
                }).then(async e => {
                    const { error, archive } = await e.json()
                    if(e.status == 200){
                        archive.forEach(e => e.key = vm.$uuid.v4() )
                        vm.archive = archive
                    } else if (e.status == 403) {
                        this.$router.push({ name: 'start-view ' })
                    }else if (error){
                        console.log(error)
                    }
                })
            
        })
    },
    data() {
      

        return {
            show: false,
            archive: [
                /*{ taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"Hello There", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"Aufgabe A", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"Aufgabe B", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },
                { taskId: "597d6a38-78f2-4dc1-8722-79f89c424e94", name:"", start:"2024-06-25 11:01", amount: 20, temperatur: 90, end: '2024-06-25 12:01', key: this.$uuid.v4() },*/
            
            ],

        }
    }
}
</script>
<style>
.flex-datetimepicker input{
    width: 100%;
    text-align: center;

    font-size: 18pt;
}
</style>