import VueRouter from 'vue-router'

import startView from '@/views/start-view.vue'
import homeView from '@/views/home-view.vue'

import loginView from '@/views/start/login-view.vue'
import registerView from '@/views/start/register-view.vue'
import forgotPassordView from '@/views/start/forgot-password-view.vue'

import tankViewVue from '../views/home/tank-view.vue'

export default new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', template: '<p>404</P>', redirect: { name: 'home' }, },

        {
            path: '/start', name: 'start-view', redirect: { name: 'login-view' }, component: startView, children: [
                { path: '/start/login', name: 'login-view', component: loginView },
                { path: '/start/register', name: 'register-view', component: registerView },
                { path: '/start/forgot-password', name: 'forgot-password-view', component: forgotPassordView },
                { path: '/start/reset-password/:token?', name: 'reset-password-view', props: true, component: require('../views/start/reset-password-view.vue').default },
                
                
            ]
        },
        { path: '/login-via-url/:token?', name: 'login-via-url-view', props: true, component: require('../views/start/login-via-url.vue').default },

        {
            path: '/home', name: 'home', component: homeView, redirect: { name: 'tank-view' }, children:[
                {
                    path: '/home/tank', name: 'tank-view', component: tankViewVue, redirect: { name: 'tank-repeat-view' }, children: [
                        { path: '/home/tank/repeat', name: 'tank-repeat-view', component: require('../views/home/tank-views/tank-view-repeat.vue').default },
                        { path: '/home/tank/task-past', name: 'tank-task-past-view', component: require('../views/home/tank-views/tank-view-tasks-past.vue').default },

                        { path: '/home/tank/control', name: 'tank-settings-view', component: require('../views/home/tank-views/tank-view-control.vue').default },
                    ] 
                },
                {   
                    path: '/home/settings', name: 'settings-view', component: require('../views/home/settings-view.vue').default 
                },
                {
                    path: '/home/menu', name: 'menu-view', component: require('../views/home/menu-view.vue').default, redirect: { name: 'menu-menu-view'},  children: [
                        { path: '/home/menu/menu', name: 'menu-menu-view', component: require('../views/menu/menu-view.vue').default },
                        { path: '/home/menu/user', name: 'menu-user-view', component: require('../views/menu/menu-user-view.vue').default },
                        { path: '/home/menu/devices', name: 'menu-devices-view', component: require('../views/menu/menu-devices-view.vue').default },

                        
                        { path: '/home/menu/impressum', name: 'menu-impressum-view', component: require('../views/menu/menu-impressum-view.vue').default },
                    ] 
                },

            ]
        },
        {
            path: '/popup/task-view/:taskId', name: 'popup-task-view', props: true, component: require('../views/popups/task-popup.vue').default
        },

        {
            path: '/popup/task-archive-view', name: 'popup-task-archive-view', props: true, component: require('../views/popups/task-archive-popup.vue').default
        },


        { path: '*', template: '<p>404</P>' } // Not found
    ]
})