<template>
    <div class="vertical-wrapper">
        <setting-header :title="title" :onlyBackButton="true" v-on:back="$router.go(-1)"></setting-header>
        <router-view></router-view>
     
        
    </div>
</template>
<script>
import settingHeader from './setting-header.vue'
export default {
    components: { settingHeader },
    name: 'device-connect',
   
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        title(){
            const routeName =  this.$route.name;
            if( routeName == 'device-add-view-wifi')
                return 'WLAN Einstellungen'
            else
                return 'Neues Gerät'
        }
    }


}
</script>