<template>
    <header class="horizontal-wrapper settings-header" style="flex: 0 0 auto">
        <md-button v-if="showRouteBack" style="margin: auto 0; flex: 0 0 15pt" v-on:click="$router.push(route)">
            <md-icon :md-src="require('./assets/arrow-left-solid.svg')"></md-icon>
        </md-button>

        <md-button v-if="onlyBackButton && !showRouteBack" style="margin: auto 0; flex: 0 0 15pt" v-on:click="$emit('back')">
            <md-icon :md-src="require('./assets/arrow-left-solid.svg')"></md-icon>
        </md-button>
        <h2 style="flex: 1 0 0; text-align: left; margin-left: 20px">{{title}}</h2>
    </header>
</template>
<script>
export default {
    name: 'setting-header',
    props: {
        title:String,
        route: [String, Object],
        onlyBackButton: { default: false}

    },
    computed:{
        showRouteBack(){
            if(typeof this.route == 'string')
                return this.route != ''
            else if (this.route)
                return Object.keys(this.route) != 0
            else
                return false
        }
    }
}
</script>