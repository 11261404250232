<template>
    <div class="vertical-wrapper" style="padding: 0 10pt;">
        <div v-if="noActiveDevice" class="vertical-wrapper">
            <div></div>
            <h2 style="color: #999">Kein Gerät hinzugefügt</h2>
            <div>
                <md-button class="md-raised md-primary" v-on:click="$router.push({name: 'device-add-view'})">hinzugefügen</md-button>
            </div>
            <div></div>

        </div>
         <div v-else class="vertical-wrapper">
            <div style="position: fixed; left: 0; width: 100%; gap: 5pt; margin-top: 20px;" class="vertical-wrapper">
                <error-notification :name="'VFS Sensor Fehler'" :value="systemStatus.vfsError"></error-notification>
                <error-notification :name="'RPS Sensor Fehler'" :value="systemStatus.rpsError"></error-notification>
                <error-notification :name="'Überfüllschutz'" :value="systemStatus.overFlowError"></error-notification>
                <error-notification :name="'Geräte Kommunikation'" :value="getDeviceCommunicationError"></error-notification>


            </div>
            <div class="horizontal-wrapper" style="flex: 0 0 130px">
                <div style="flex: 2 0 0; margin: auto" >
                    <svg height="130"  viewBox="0 0 40 56" xmlns="http://www.w3.org/2000/svg">
                        <path d="m 4 15 q -4 0 -4 4 l 0 33 q 0 4 4 4 l 32 0 q 4 0 4 -4 v -33 q 0 -4 -4 -4 l -12 0 l 2 4 l 8 0 q 2 0 2 2 l 0 29 q 0 2 -2 2 v 0 l -28 0 q -2 0 -2 -2 l 0 -29 l 0 0 q 0 -2 2 -2 v 0 l 8 0 l 0 0 l 2 -4 l -12 0 M 0 5 l 2 0 l 1 1 l 8 0 q 1 0 1 -1 c 0 -1 -2 -1 -4 -1 c -1 0 -1 -1 0 -1 C 9 3 11 3 12 2 C 13 1 13 1 14 2 C 15 3 17 3 18 3 C 19 3 19 4 18 4 C 16 4 14 4 14 5 Q 14 6 15 6 C 20 6 22 7 22 10 L 23 10 L 23 11 L 17 11 L 17 10 L 18 10 C 18 9 17 9 15 9 C 14 10 12 10 11 9 L 3 9 L 2 10 L 0 10 L 0 5" fill="#333"/>
                    
                        <rect v-if="systemStatus.filling" x="18" y="11" width="4" height="28" fill="#11aebf"></rect>

                        <path d="M 4 36 l 0 1 l 0 -1 c 4 1 4 -2 16 0 c 8 1 6 -1 16 0 l 0 14 q 0 2 -2 2 l -28 0 q -2 0 -2 -2 c 0 -4.3333 0 -8.6667 0 -13" fill="var(--color3)"/>
                        </svg>
                </div>
                <div class="horizontal-wrapper" style="flex: 3 0 0; margin: 0 5pt;">
                    <div class="vertical-wrapper" style=" margin: auto 5pt;">
                        <span style="text-align:right;">Durchfluss:</span> 
                        <span v-if="getDisplayHeating" style="text-align:right;">Temperatur:</span> 

                        <span style="text-align:right;">Befüllung:</span> 
                        <span style="text-align:right;">Auffüllen:</span> 

                    </div>
                    <div class="vertical-wrapper" style="flex: 2 0 0; margin: auto 5pt; ">
                        <span style="text-align:left; font-weight: bold">{{ systemStatus.flow.toFixed(2)}} l/min</span> 
                        <span v-if="getDisplayHeating"  style="text-align:left; font-weight: bold">{{ systemStatus.temperatur.toFixed(2) }} °C</span>

                        <span style="text-align:left; font-weight: bold"> {{ systemStatus.filling? 'Ein' : 'Aus'}}</span> 
                        <span style="text-align:left; font-weight: bold"> {{ systemStatus.amountInQueue.toFixed(2) }} l</span> 

                    </div>
                </div>
            </div>
     
            <div class="navbar">
                <router-link v-ripple="'rgba(249, 156, 27, 0.35)'" :to="{ name: 'tank-repeat-view' }">Aufgaben</router-link>
                <router-link v-ripple="'rgba(249, 156, 27, 0.35)'" :to="{ name: 'tank-task-past-view' }">Inaktiv</router-link>
                <router-link v-ripple="'rgba(249, 156, 27, 0.35)'" :to="{ name: 'tank-settings-view' }">Hand</router-link>

            </div>

            <router-view></router-view>

        </div>
      
      
     

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import errorNotificationVue from '../../components/error-notification.vue'

export default {
    name: 'tank-view',
    components: {
        'error-notification':errorNotificationVue
    },
    computed:{
        systemStatus(){
            return this.$store.getters.getSystemStatus
        },
        ...mapGetters([
            'noActiveDevice',
            'getDeviceCommunicationError',
            'getDisplayHeating'
        ])

    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            console.log('before Route Enter');
            
            vm.$store.dispatch('fetch-active-device')
            vm.$store.dispatch('sync-tasks')
            vm.$store.dispatch('fetch-system-state')
            vm.stateRefreshTimer = setInterval(() => { vm.$store.dispatch('fetch-system-state') }, 1000 * 1 )
            
        })
    },
    beforeRouteLeave(to, from, next){
        if(this.stateRefreshTimer)
            clearInterval(this.stateRefreshTimer)

        next()
    },
    data(){
        return{
            repeatFill: 0,
            stateRefreshTimer: 0
        }
    }
}
</script>