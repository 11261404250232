<template>
    <div class="vertical-wrapper">
        <setting-header title="User" :route="{name: 'menu-menu-view'}" />

        <div class="vertical-wrapper" style="padding: 0 10pt">
            <md-field style="flex: 0 0 auto">
                <label for="">Name</label>
                <md-input v-model="user.name"></md-input>
            </md-field>

            <md-field style="flex: 0 0 auto">
                <label for="">E-Mail</label>
                <md-input v-model="user.email"></md-input>
            </md-field>

            <md-switch v-model="changePassword" class="md-primary" style="flex: 0 0 auto">Passwort ändern</md-switch>

            <div v-if="changePassword" class="vertical-wrapper" >
                <md-field style="flex: 0 0 auto">
                    <label for="">Passwort</label>
                    <md-input type="password" v-model="pw"></md-input>
                </md-field>
                <md-field style="flex: 0 0 auto">
                    <label for="">Passwort wiederholen</label>
                    <md-input type="password" v-model="pw1"></md-input>
                </md-field>
            </div>
            <md-button class="md-raised md-accent" style="margin-top: 20pt" v-on:click="save">Speichern</md-button>
            <div></div>
            <md-button class="md-primary md-raised" v-on:click="logout">Abmelden</md-button>
        </div>

    </div>
</template>
<script>
import settingHeaderVue from '../../components/setting-header.vue';

export default {
    name: 'menu-user-view',
    components: {
        'setting-header': settingHeaderVue
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        user(){
            return this.$store.getters.getUser
        }
        
    },
    methods:{
        logout(){
            this.$store.commit('set-token', '')
            this.$router.push({ name: 'login-view'})
        },
        save(){
            
            if(this.changePassword && this.pw == '' && this.pw != this.pw1){
                this.$swal.fire({ title: 'Achtung', icon: 'warning', text: 'Passwörter stimmen nicht überein' })
                return
            }
            fetch(`${this.$hostname}/user/edit`, {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": this.token 
                },
                body: JSON.stringify({
                    name: this.user.name,
                    email: this.user.email,

                    changePassword: this.changePassword, 
                    password: this.pw
                })
            }).then(async e => {
                if (e.status == 200) {
                    this.$swal({title: 'Erfolg', icon: 'success', text: 'Einstellungen erfolgreich geändert'})
                } else if (e.status == 403) {
                    this.$router.push({ name: 'start-view ' })
                }
            })

        }
    },
  
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$store.dispatch('fetch-user')
            
        })
    },
    data(){
        return{

            changePassword: false,
            pw: '', pw1: ''
        }
    }
}
</script>