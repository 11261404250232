<template>
    <div>
        <div class="vertical-wrapper" style="margin: 5pt">

            <md-field>
                <label for="">Username</label>
                <md-input v-model="username" v-on:keyup.enter="register"></md-input>
            </md-field>
            <md-field>
                <label for="">E-Mail</label>
                <md-input v-model="email" v-on:keyup.enter="register"></md-input>
            </md-field>

            <md-field>
                <label for="">Password</label>
                <md-input type="password" v-model="pw" v-on:keyup.enter="register"></md-input>
            </md-field>
            <md-field>
                <label for="">Password wiederholen</label>
                <md-input type="password" v-model="pw1" v-on:keyup.enter="register"></md-input>
            </md-field>

            <md-button class="md-primary md-raised" style="margin: 0" v-on:click="register">Registrieren</md-button>
        </div>


    </div>
</template>
<script>
export default {
    name: 'register-view',
    methods:{
        register(){
            // check if passwords are even and not empty string
            if(this.pw == this.pw1 && this.pw != ''){
                // post method for user add
                // this lets you only create one user with that name
                fetch(`${this.$hostname}/user/add`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: this.email,
                        name: this.username,
                        password: this.pw,
                    })
                }).then(async e => {
                   if (e.status == 200) {
                        
                        const { authendification, error } = await e.json()
                        if(authendification){
                            // save authendification token
                            this.$store.commit('set-token', authendification)
                            // move to home screen
                            this.$router.push({ name: 'home' })

                        }
                        else if(error)
                            this.$swal.fire({ title: 'Registrierung fehlgeschlagen', text: error, icon: 'error'})
                    }

                })
            }else{
                // E
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Registrieren fehlgeschlagen',
                    text: 'Passwörter müssen übereinstimmen'
                })
            }
        }
    },
    data(){
        return{
            loginAtempt: false,
            username: '', 
            email: '',
            pw: '',
            pw1: '',
        }
    }
}
</script>