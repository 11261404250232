<template>
    <div class="vertical-wrapper">
        <settings-header title="Geräte" :route="{name:'menu-menu-view'}"/>
        <div class="vertical-wrapper" style="padding: 0 10pt">
            <md-field style="flex: 0 0 auto">
                <label for="">Gerät</label>
                <md-select v-model="activeDeviceId">
                    <md-option v-for="d in devices" :key="d.key" :value="d.deviceId">{{d.name}}</md-option>
                </md-select>
            </md-field>
            <h3 style="margin-top: 5pt; margin-bottom: 2pt;  text-align:left;">Einstellungen</h3>
            <md-field>
                <label for="">Bezeichnung</label>
                <md-input v-model="deviceName" @blur="changesDetected"></md-input>
            </md-field>
            <h3 style="margin-top: 5pt; margin-bottom: 2pt; text-align:left;">Anlernen</h3>
            
            <sensor-input :name="'Durchfluss Sensor'" v-model="deviceVFS"></sensor-input>
            <sensor-input :name="'Temperatur Sensor'" v-model="deviceRPS"></sensor-input>

            
            <h3 style="margin-top: 5pt; margin-bottom: 2pt;  text-align:left;">Ländereinstellungen</h3>
            <md-autocomplete v-model="deviceTimezone" :md-options="timezones">
                <label>Zeitzone</label>
            </md-autocomplete> 
            <h4>{{ $moment.unix(systemStatusRTC).format('YYYY-MM-DD HH:mm:ss') }}</h4>
            <!--
            <md-field>
                <label for="">Zeitzone</label>

                <md-select v-model="deviceTimezone">


                        <md-option v-for="timezone in timezones" :key="timezone" :value="timezone">
                            {{ timezone }}
                        </md-option>
                    </md-select>
                    
                </md-field>
            -->

            
        </div>
       
        
        <md-button v-if="!valueHasChanged" class="md-fab md-primary md-invert" style="position: absolute; right: 5px; bottom: 65px; margin-bottom: env(safe-area-inset-bottom);
            height: 45pt; width: 45pt;" v-on:click="addDevice">
                <md-icon :md-src="require('../../assets/plus-solid.svg')" style="fill: white;" ></md-icon>
        </md-button>
     
        <md-button v-if="valueHasChanged" class="md-fab md-plain md-raised" style="position: absolute; right: 5px; bottom: 135px; margin-bottom: env(safe-area-inset-bottom);
            height: 45pt; width: 45pt;" v-on:click="discardChanges">
                    <md-icon :md-src="require('../../assets/xmark-solid.svg')" style="fill: #999;" ></md-icon>
        </md-button>

        <md-button v-if="valueHasChanged" class="md-fab md-accent md-invert" style="position: absolute; right: 5px; bottom: 65px; margin-bottom: env(safe-area-inset-bottom);
            height: 45pt; width: 45pt; " v-on:click="saveDevice">
                        <md-icon :md-src="require('../../assets/floppy-disk-solid.svg')" style="fill: white;" ></md-icon>
        </md-button>
    </div>
</template>
<script>
import SensorInput from '@/components/sensor-input.vue';
import settingHeaderVue from '../../components/setting-header.vue';
import moment from 'moment-timezone';
const TIMEZONES = moment.tz.names()

export default {
    name: 'menu-devices-view',
    components:{
        'settings-header':settingHeaderVue,
        'sensor-input': SensorInput
    },
    watch:{
        enableWAPMode(){
            if(this.enableWAPMode){
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Sicherheitsrisiko', 
                    text: `Mit dem Aktivieren des Access Points können die WLAN Einstellungen von anderen bearbeitet werden`
                })
            }else{
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Funktionen eingeschränkt',
                    text: `Mit dem Deaktivieren des Access Points können die WLAN Einstellungen vor erst nicht mehr ändern`
                })
            }
        }
    },
    methods:{
        changesDetected(){
            this.valueHasChanged = true && !this.blockChanges;
        },
        addDevice(){
            this.$router.push({name: 'device-add-view'})
        },
        saveDevice(){
            const device =  this.devices.find(e => e.deviceId == this.activeDeviceId)
            if(!device)
                return

            fetch(`${this.$hostname}/devices/edit`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.token
                },
                body: JSON.stringify({
                    name: device.name,
                    deviceId: device.deviceId,
                    parameter: this.$store.getters.getTankParameters
                })
            }).then(async e => {
                const { error } = await e.json()
                if (e.status == 200) {
                    console.log(error);
                    this.valueHasChanged = false;

                }else if(e.status == 403){
                    this.$router.push({ name : 'start-view '})
                }
            })
        },

        setActiveDevice(deviceId){
            
            fetch(`${this.$hostname}/devices/set-active`, {
                method: 'POST',
                headers: {
                    "Authorization": this.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    deviceId
                })
            }).then(async e => {
                if(e.status == 200){
                    this.$store.dispatch('fetch-active-device')

                    this.blockChanges = true
                    setTimeout(() => (this.blockChanges = false),1000)
                    
                } else if (e.status == 403) {
                    this.$router.push({ name: 'start-view ' })
                }
            })

        },
        discardChanges(){
            this.$swal.fire({ 
                icon: 'question', 
                title: 'Änderungen verwerfen?', 
                text: 'Alle Änderungen werden nicht übernommen',
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                showDenyButton: true,
                denyButtonText: 'Nein'
            
            }).then(({isConfirmed}) =>{
                if(isConfirmed){
                    this.$router.push({ name: 'tank-view' })
                }
            })
        }

    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        activeDeviceId:{
            set(v){
                this.setActiveDevice(v)
                this.$store.commit('set-active-device', { deviceId: v})
            },
            get(){
                return this.$store.getters.getActiveDeviceId
            }
        },
        deviceName:{
            get(){
                const m = this.devices.find(e => e.deviceId == this.activeDeviceId)
                if(m)
                    return m.name
                else
                    return ''
            },
            set(v){
                const m =  this.devices.find(e => e.deviceId == this.activeDeviceId)
                if(m){
                    m.name = v
                }
            }
        },
        deviceVFS: {
            set(v) {
                this.valueHasChanged = true && !this.blockChanges;

                this.$store.getters.getTankParameters['vfs'] = v
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get() {
                return this.$store.getters.getTankParameters['vfs']
            }
        },
        deviceRPS: {
            set(v) {
                this.valueHasChanged = true && !this.blockChanges;

                this.$store.getters.getTankParameters['rps'] = v
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get() {
                return this.$store.getters.getTankParameters['rps']
            }
        },

        deviceTimezone: {
            set(v) {
                this.valueHasChanged = true && !this.blockChanges;

                this.$store.getters.getTankParameters['timezone'] = v
                this.$store.commit('set-tank-parameters', this.$store.getters.getTankParameters)
            },
            get() {
                return this.$store.getters.getTankParameters['timezone']
            }
        },
        systemStatus(){
            return this.$store.getters.getSystemStatus
        },
        systemStatusRTC(){
            return this.$store.getters.getSystemRTC
        },
        systemDateTime(){
            const now = this.systemStatusRTC;
            return this.$moment(now).format('YYYY-MM-DD HH:mm:ss')
        }


       
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$store.dispatch('fetch-active-device');

            fetch(`${vm.$hostname}/devices/read`, {
                method: 'POST',
                headers: { 
                    "Authorization": vm.token,
                    "Content-Type": "application/json"
                },
            }).then(async e => {
                if (e.status == 200) {
                    const { devices, error } = await e.json()
                    if (devices) {
                        devices.forEach(e => e.key = vm.$uuid.v4())
                        vm.devices = devices
                    }else if( error){
                        console.log(error);
                    } 

                } else if (e.status == 403) {
                    this.$router.push({ name: 'start-view' })
                }
            })
        })
    },
    data(){
        return{
            devices:[
            ],
            blockChanges: false,
            valueHasChanged: false,

            enableWAPMode: true,
            //timezone: ['Europe/Berlin'],
            timezones: TIMEZONES,

            
        }
    }
}
</script>