<template>
    <div class="md-button-selector horizontal-wrapper" style="flex: 0 0 auto">
        <div 
            v-for="o, i in options" :key="o + i" 
            class="item" 
            :class="{'active': value == o}" 
            :style="value == o? `background-color: ${activeColors[i]}`: ''"
            v-on:click="setValue(o)"
            >{{o}}</div>
    </div>
</template>
<script>
export default {
    name : 'md-button-selector',
    props: { options: Array, value: String, colors: Array },
    methods: {
        setValue(o){
            
            this.$emit('input', o)
        }
    },
    data(){
        const colors = this.$props.colors && this.$props.colors.length == this.$props.options.length? this.$props.colors: this.$props.options.map(() => 'auto')

        return{
            activeColors: colors
        }
    }
}
</script>
<style>
.md-button-selector{
    box-shadow: #ccc 0pt 0pt 1pt 1pt; 
    border-radius:  5pt;
}
.md-button-selector .item{
    padding: 5pt;
    font-weight: bold;

}
.md-button-selector .item:nth-child(1){
    border-radius: 5pt 0pt 0pt 5pt;
}
.md-button-selector .item:nth-last-child(1){
    border-radius:  0pt 5pt 5pt 0pt ;
}
.md-button-selector .item.active{
    background-color: var(--color2);
    color: white;
}

.md-button-selector.md-accent .item.active{
    background-color: var(--color3);
    color: white;

}

</style>