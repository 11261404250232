const moment = require('moment')
module.exports = {
    getNextDate: function (task) {
        if (task.repeat == 0)
            return task.date;
        // daily repeat
        else if (task.repeat == 1) {
            const date = moment()
            // check if today time is larger than next time
            const addDays = date.format('HH:mm') > task.time ? 1 : 0;

            return `${date.add(addDays, 'days').format('YYYY-MM-DD')} ${task.time}`
        }
        //weekly repeate
        else if (task.repeat == 2) {
            const date = moment()
            for (let i = 0; i < 7; i++) {
                let currentWeekDay = date.weekday()

                // check if the current weekday is the next 
                // and when i == 0 means today if time is already passed
                if (task.weekdays.find(e => e == currentWeekDay && (i || date.format('HH:mm') < task.time)))
                    break
                // add one date
                date.add(1, 'days')
            }
            return `${date.format('YYYY-MM-DD')} ${task.time}`
        }
    },
  
}