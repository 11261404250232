<template>
    <div v-if="showNotification" class="horizontal-wrapper error-notification" >
        <div style="flex: 0 0 20pt; margin: 0 4pt" class="icon" >
            <md-icon :md-src="require('../assets/triangle-exclamation-solid.svg')" class="icon-grow icon-fill-red" color="white"></md-icon>
        </div>
        <div style="flex: 1 0 0">
            <p style="font-weight: bold">{{ name }}</p>
        </div>
        <div class="icon" style="flex: 0 0 20pt; margin: 0 4pt" background-image="" v-on:click="hideNotification">
            <md-icon :md-src="require('../assets/xmark-solid.svg')" class="icon-grow icon-fill-white"></md-icon>

        </div>
    </div>
</template>
<script>
export default {
    name: 'error-notification',
    props: { name: String, value: [Boolean, Number] },
    methods: {
        hideNotification(){
            
            this.hideByTimer = true;

            setTimeout(() => {
                this.hideByTimer = false;

            }, 15 * 1000)

        }
    },
    computed:{
        showNotification(){
            return !this.hideByTimer && this.value
        }
    },
    data(){
        return{
            hideByTimer: false,
        }
    }

}
</script>
<style>
.error-notification{
    flex: 0 0 auto;

    background-color: rgba(0, 0, 0, .7);
    color: white;

    margin: 0 10pt;
    border-radius: 5pt;
}
.icon-grow{
    width: 100%;
    height: 100%;
}
.icon-fill-white path{
    fill: white;
}
.icon-fill-red path{
    fill: red;
}
</style>