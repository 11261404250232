<template>
    <div >
        <h3 v-if="tasks.length == 0" style="color: #aaa">keine Aufgabe festgelegt</h3>
        <div v-for="task in tasks.filter(e => (e.active && !this.$props.showInactive) || (this.$props.showInactive && !e.active))" :key="task.taskId" 
            v-ripple 
            class="horizontal-wrapper"
            style="margin: 10pt 5pt; padding: 2pt; background-color: white" :style="`box-shadow: ${task.inProgress? '#4ef57b': '#ccc'}  0pt 0pt 2pt 1pt`" 
            v-on:click="edittask(task)">
            <div style="flex: 0 0 30pt" class="vertical-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" style="margin: auto; height: 23pt" viewBox="0 0 512 512">
                    <!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <circle v-if="task.repeat == 0" cx="256" cy="256" r="200" fill="transparent" :stroke="task.active? 'var(--color2)' : '#ccc'" style="stroke-width: 50px"></circle>
                    <text v-if="task.repeat == 0" x="178" y="330" style="font: bold 240px sans-serif;" :fill="task.active ? 'var(--color2)' : '#ccc'">1</text>
                    
                    <path v-if="task.repeat > 0" d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z" :fill="task.active ? 'var(--color2)' : '#ccc'"/>
                    <text v-if="task.repeat == 1" x="198" y="332" style="font: bold 200px sans-serif;" :fill="task.active? 'var(--color2)' : '#ccc'">T</text>
                    <text v-if="task.repeat == 2" x="178" y="332" style="font: bold 190px sans-serif;" :fill="task.active? 'var(--color2)' : '#ccc'">W</text>

                </svg>
            </div>
            <div class="vertical-wrapper">
                <h3 style="margin: 2pt 5pt; text-align: left;" >{{ task.name }}</h3>
                <div class="horizontal-wrapper" style="margin: 2pt 5pt;">
                    <span>{{ getNextDate(task) }}</span>
                    <div style="flex: 2 0 0 "></div>
                    <span>{{ task.amount }}l</span>
                    <div v-if="task.heating" ></div>
                    <span v-if="task.heating">{{ task.temperatur }}°C</span>


                </div>
            </div>
        


        </div>
    </div>
</template>
<script>

export default {
    name: 'task-calendar',
    props: ['show-inactive'],
    methods:{
        getNextDate(task){
            if(task.repeat == 0)
                return task.date;
            // daily repeat
            else if (task.repeat == 1){
                const date = this.$moment()
                // check if today time is larger than next time
                const addDays = date.format('HH:mm') > task.time? 1:0;

                return `${date.add(addDays, 'days').format('YYYY-MM-DD')} ${task.time}`
            }
            //weekly repeate
            else if (task.repeat == 2){
                const date = this.$moment()
                for(let i = 0; i < 7; i++){
                    let currentWeekDay = date.weekday()
                 
                    // check if the current weekday is the next 
                    // and when i == 0 means today if time is already passed
                    if(task.weekdays.find(e => e == currentWeekDay && (i || date.format('HH:mm') < task.time) ))
                        break
                    // add one date
                    date.add(1, 'days')
                }
                return `${date.format('YYYY-MM-DD')} ${task.time}`
            }
        },
        edittask(a){
            this.$router.push({ name: 'popup-task-view', params: { taskId: a.taskId } })

        },
     
    },
    computed:{
        // resort task object into array 
        applicationsArray(){
            const taskObject = this.$store.getters.getTasks

            const taskArray = Object.keys(taskObject).map((key) => {
                const o = taskObject[key]
                o.taskId = key
                o.nextDate = this.getNextDate(o)
                return o 
            })

            return taskArray.sort((a, b) => this.$moment(a.nextDate).valueOf() - this.$moment(b.nextDate).valueOf()  )
        },
       
        tasks(){
            return this.$store.getters.getTasks;

        },
       
    },
    data(){
        return {

        }
    }
}
</script>