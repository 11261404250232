<template>
    <div class="vertical-wrapper" style="overflow: scroll; ">
        <appointment-calendar :show-inactive="true"></appointment-calendar>

        <md-button class="md-fab md-primary md-invert" style="position: absolute; right: 5px; bottom: 65px; 
            height: 35pt; width: 35pt; margin-bottom: env(safe-area-inset-bottom)" v-on:click="openHistory">
                <md-icon :md-src="require('../../../assets/box-archive-solid.svg')" style="fill: white;" ></md-icon>
        </md-button>
    </div>

</template>
<script>
import appointmentCalendarVue from '@/components/appointment-calendar.vue'

export default {
    name: 'tank-view-task-past',
    components: {
        'appointment-calendar': appointmentCalendarVue
    },
    methods:{
        openHistory(){
            this.$router.push({ name: 'popup-task-archive-view'})

        }
    }
}
</script>