<template>
    <div style="padding: 0 20pt">

        <h2>Menü</h2>
        <div class="vertical-wrapper settings-menu" style="margin-top: 20px">
            <router-link v-ripple :to="{name: 'menu-user-view'}" class="item">Profil Einstellungen</router-link>

            <router-link v-ripple :to="{ name: 'menu-devices-view' }" class="item">Geräte</router-link>
            
            <!--<router-link v-ripple :to="{ name: 'menu-devices-view' }" class="item">Termine</router-link>-->

            <router-link v-ripple :to="{name: 'menu-impressum-view'}" class="item">Informationen</router-link>

        </div>
    </div>
</template>
<script>
export default {
    name: 'menu-view'
}
</script>
<style>
.settings-menu a.item{
    padding-top: 10pt;
    padding-bottom: 10pt;
    font-size: 15pt;
    margin: 10pt 0;
    box-shadow: 0 2px 3px 0px rgb(0 0 0 / 20%);
    background-color: white;
}
.settings-menu a.item:first-child {
}
</style>