<template>
    <div class="vertical-wrapper" style="margin: 3.5%;">
        <h2>Passwort vergessen</h2>
        <md-field style="flex: 0 0 auto">
            <label for="">E-Mail</label>
            <md-input v-model="email"  v-on:keyup.enter="forgotPassword"></md-input>
        </md-field>
        <md-button class="md-primary md-raised" style="margin: 0" v-on:click="forgotPassword" >Absenden</md-button>
    </div>
</template>
<script>
export default {
    name: 'forgot-password-view',
    methods:{
        forgotPassword(){
            fetch(`${this.$hostname}/user/send-reset-password`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": this.token
                },
                body: JSON.stringify({
                    email: this.email,
                })
            }).then(async e => {
                if (e.status == 200) {
                    const { error } = await e.json()
                    if (error) {
                        this.$swal.fire({ title: 'Fehler', icon: 'error', text: error })
                    } else
                        this.$swal.fire({ title: 'Erfolg', icon: 'success', text: error ? error : 'Passwort zurückgesetzt, melden Sie sich erneut an' })

                    this.email = ''
                } 
            })
        }
    },
    data(){
        return {
            email: ''
        }
    }
}
</script>